import Head from "next/head";
import React from "react";
import Swiper from "swiper";
import { useI18n } from "../domain/i18n";
import { SlideshowItem } from "../domain/slideshow";

interface CoverSlideshowProps {
  items: Array<SlideshowItem>;
}

export default function CoverSlideshow({ items }: CoverSlideshowProps) {
  const I18n = useI18n();
  const swiperRef = React.useRef<HTMLDivElement & { swiper: Swiper }>(null);
  const swiperPrevRef = React.useRef<HTMLDivElement>(null);
  const swiperNextRef = React.useRef<HTMLDivElement>(null);
  const swiperPaginationRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    let swiper: Swiper | undefined;
    if (
      swiperRef.current &&
      swiperPrevRef.current &&
      swiperNextRef.current &&
      swiperPaginationRef.current
    ) {
      swiper = new Swiper(swiperRef.current, {
        loop: true,
        speed: 1000,
        autoplay: {
          delay: 10000,
          disableOnInteraction: false,
        },
        navigation: {
          prevEl: swiperPrevRef.current,
          nextEl: swiperNextRef.current,
        },
        pagination: {
          el: swiperPaginationRef.current,
          clickable: true,
          bulletClass: "cover-slideshow__pagination__item",
          bulletActiveClass: "is-active",
        },
      });
    }
    return () => swiper && swiper.destroy(true, true);
  }, []);

  return (
    <>
      <Head>
        <link rel="stylesheet" href="https://unpkg.com/swiper@5.4.5/css/swiper.min.css" />
      </Head>
      <div
        ref={swiperRef}
        className="swiper-container"
        style={{ height: "100vh", background: "#24195D" }}
      >
        <div className="swiper-wrapper">
          {items.map((item, i) => (
            <div key={i} className="swiper-slide">
              <div className="cover-slideshow__slide" style={{ width: "100vw", height: "100vh" }}>
                <div
                  className="cover-slideshow__slide__background"
                  style={{ backgroundImage: `url("${item.imgSrcLarge}")` }}
                />
                <div
                  className="cover-slideshow__slide__content"
                  style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <h1>{item.title}</h1>
                  <section dangerouslySetInnerHTML={{ __html: item.body }} />
                </div>
              </div>
            </div>
          ))}
        </div>
        <span ref={swiperPrevRef} className="cover-slideshow__previous" style={{ zIndex: 2 }}>
          <i className="icon icon-arrow-left" />
        </span>
        <span ref={swiperNextRef} className="cover-slideshow__next" style={{ zIndex: 2 }}>
          <i className="icon icon-arrow-right" />
        </span>
        <div ref={swiperPaginationRef} className="cover-slideshow__pagination" />
        <a href="#scroll-section-1" className="landing-page__cover__scroll" data-scroll>
          {I18n.t("home.scroll-indicator")}{" "}
          <div className="landing-page__cover__scroll__indicator scroll-indicator"></div>
        </a>
      </div>
    </>
  );
}
