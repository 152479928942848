import React from "react";
import { useI18n } from "../domain/i18n";

export const Footer = ({ slim }: { slim?: boolean }) => {
  const I18n = useI18n();

  return (
    <>
      <footer className="site-footer">
        {!slim && (
          <div className="site-footer__primary">
            <div className="site-footer__content">
              <ul className="kv-list kv-list--inverted float-left">
                <li className="kv-list__item">
                  <a
                    href={`mailto:${I18n.t("footer.email")}`}
                    className="button button--inline button--secondary float-left"
                  >
                    <i className="icon-mail"></i> {I18n.t("footer.email")}
                  </a>
                </li>
              </ul>

              <ul className="kv-list kv-list--inverted float-right">
                <li className="kv-list__item">
                  <span className="kv-list__key">
                    {I18n.t("frontend.social-media.follow.label")}
                  </span>
                  <span className="kv-list__value">
                    <a
                      href="https://www.facebook.com/publicvalueresearch"
                      className="button button--inline button--secondary mls"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="icon-facebook"></i>{" "}
                      {I18n.t("frontend.social-media.follow.facebook")}
                    </a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        )}
        <div className="site-footer__secondary">
          <div className="site-footer__content">
            <div className="site-footer__toolbar__left">
              <ul className="horizontal-list horizontal-list--compact">
                <li className="horizontal-list__item-container">
                  <a href={`/${I18n.locale}/rechtliches`} className="horizontal-list__item">
                    {I18n.t("footer.legal")}
                  </a>
                </li>
                <li className="horizontal-list__item-container">
                  <a href={`/${I18n.locale}/methodik`} className="horizontal-list__item">
                    {I18n.t("footer.method")}
                  </a>
                </li>
                <li className="horizontal-list__item-container">
                  <a href={`/${I18n.locale}/faq`} className="horizontal-list__item">
                    {I18n.t("footer.faq")}
                  </a>
                </li>
              </ul>
            </div>
            <div className="site-footer__toolbar__right">
              <ul className="horizontal-list horizontal-list--compact">
                <li className="horizontal-list__item-container">
                  {`${I18n.t("footer.copyright")} ${copyrightYear(2014)} `}
                  <a
                    href={I18n.t("footer.clvs.url")}
                    className="horizontal-list__item"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="xl-hide">{I18n.t("footer.clvs.short")}</span>
                    <span className="s-hide xl-show">{I18n.t("footer.clvs.long")}</span>
                  </a>
                  {!/missing/.test(I18n.t("footer.other.url")) && (
                    <>
                      {" & "}
                      <a
                        href={I18n.t("footer.other.url")}
                        className="horizontal-list__item"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="xl-hide">{I18n.t("footer.other.short")}</span>
                        <span className="s-hide xl-show">{I18n.t("footer.other.long")}</span>
                      </a>
                    </>
                  )}
                </li>
                <li className="horizontal-list__item-container">
                  {I18n.t("footer.created-by")}{" "}
                  <a
                    href="http://interactivethings.com/"
                    className="horizontal-list__item"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {I18n.t("footer.ixt")}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

function copyrightYear(startYear: number) {
  const currentYear = new Date().getFullYear();
  return startYear === currentYear ? startYear : `${startYear}–${currentYear}`;
}
